var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "base-material-card",
                {
                  staticClass: "px-5 py-3 mt-7",
                  staticStyle: { width: "100%" },
                  attrs: {
                    color: "accent",
                    icon: "mdi-file-image",
                    inline: ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "after-heading",
                      fn: function() {
                        return [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6", lg: "4" } },
                                [_c("ViewAsCustomer")],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("ContentLibraryMain", {
                    attrs: {
                      root: _vm.root,
                      "file-upload-accepted-extensions":
                        _vm.accepetedFileExtensions
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }